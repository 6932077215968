<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Jobs Overview" />

    <DataTable
      :table-key="tableKey"
      row-key="product_id"
      title="Active Jobs"
      :columns="columns"
      :rows="jobs"
      :loading="loading"
      :pagination="pagination"
      :downloadable="false"
      :refreshable="true"
      :customizable="true"
      :filterable="true"
      :server-side="true"
      :searchable="true"
      :disable-state="false"
      :dimension-filter-options-by-column="dimensionFilterOptionsByColumn"
      @update-rows="val => getJobs(val)"
    >
      <template v-slot:destination_url="row">
        <a :href="row.destination_url" target="_blank">
          {{
            row.destination_url.length > 40
              ? row.destination_url.substr(0, 40) + "..."
              : row.destination_url
          }}
        </a>
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import { QImg } from "quasar";
import axios from "axios";
import DataTable from "@/components/DataTable";
import DataTableMixin from "@/mixins/DataTableMixin";

export default {
  name: "InventoryJobsOverview",
  mixins: [DataTableMixin],
  components: {
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      tableKey: "jobsOverview",
      jobs: [],
      pendingGetJobsRequests: [],
      pagination: {
        sortBy: "product_id",
        descending: true,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 1000
      },
      dimensionFilterOptionsByColumn: {},
      columns: [
        {
          name: "business_id",
          label: "Business ID",
          field: "business_id",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "business_name",
          label: "Business",
          field: "business_name",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "inventory_source_id",
          label: "Inventory Source ID",
          field: "inventory_source_id",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "inventory_source_name",
          label: "Inventory Feed",
          field: "inventory_source_name",
          type: "dimension",
          sortable: false,
          visible: false
        },
        {
          name: "product_id",
          label: "Product ID",
          field: "product_id",
          type: "dimension",
          sortable: true,
          filterable: false
        },
        {
          name: "soc_code",
          label: "SOC Code",
          field: "soc_code",
          sortable: true,
          filterable: true,
          type: "dimension"
        },
        {
          name: "soc_title",
          label: "SOC Title",
          field: "soc_title",
          format: val => (val.length > 30 ? val.substr(0, 30) + "..." : val),
          sortable: true,
          filterable: true,
          type: "dimension"
        },
        {
          name: "title",
          label: "Title",
          field: "title",
          format: val => (val.length > 30 ? val.substr(0, 30) + "..." : val),
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        {
          name: "category",
          label: "Category",
          field: "category",
          format: val => (!val ? "-" : val),
          sortable: true,
          filterable: true,
          type: "dimension"
        },
        {
          name: "company",
          label: "Company",
          field: "company",
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        {
          name: "logo_image_url",
          label: "Logo Image",
          hideField: true,
          isComponentField: true,
          field: row => ({
            component: QImg,
            componentProperties: {
              src: row.logo_image_url,
              fit: "contain",
              width: "70px",
              style: "width: 70px; height: 50px;"
            },
            componentBody: ""
          }),
          sortable: false,
          type: "dimension",
          filterable: false
        },
        {
          name: "city",
          label: "City",
          field: "city",
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        {
          name: "state",
          label: "State",
          field: "state",
          sortable: true,
          filterable: true,
          type: "dimension"
        },
        {
          name: "postal_code",
          label: "Postal Code",
          field: "postal_code",
          sortable: true,
          filterable: false,
          type: "dimension"
        },
        /*{
          name: "date_listed",
          label: "Date Listed",
          field: "date_listed",
          sortable: true,
          filterable: true,
          type: "metric",
          metricType: "number"
        },*/
        {
          name: "destination_url",
          label: "Destination URL",
          field: "destination_url",
          hideField: true,
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  beforeMount() {
    // Account for possible business id / business inventory id initial filters params
    let initialUrlParams = new URLSearchParams(window.location.search);

    let initialFilteredBusinessId = initialUrlParams.get("business_id");
    let initialFilteredInventorySourceId = initialUrlParams.get(
      "inventory_source_id"
    );
    if (initialFilteredBusinessId || initialFilteredInventorySourceId) {
      window.history.replaceState(history.state, "", "?");

      this.columns = this.columns.map(column => {
        if (column.name === "business_id" && initialFilteredBusinessId) {
          column.filterValues = {
            in: [initialFilteredBusinessId],
            notIn: [],
            gt: "",
            lt: ""
          };
          column.activeFilterCount = 1;
        } else if (
          column.name === "inventory_source_id" &&
          initialFilteredInventorySourceId
        ) {
          column.filterValues = {
            in: [initialFilteredInventorySourceId],
            notIn: [],
            gt: "",
            lt: ""
          };
          column.activeFilterCount = 1;
        }

        return column;
      });
    } else {
      let urlParamDataTableState = this.loadDataTableStateFromURLParam(
        this.tableKey,
        this.columns
      );

      this.columns = urlParamDataTableState.columns;

      if (Object.keys(urlParamDataTableState.pagination).length > 0) {
        this.pagination = urlParamDataTableState.pagination;
      }
      if (urlParamDataTableState.searchFilter.length > 0) {
        this.searchFilter = urlParamDataTableState.searchFilter;
      }
    }
  },
  mounted() {
    this.getJobs();
  },
  methods: {
    getJobs(dataTableState = null) {
      this.loading = true;

      // If we dont have a dataTableState (passed in events emitted by the datatable), then fallback to default initial request.
      if (!dataTableState) {
        dataTableState = {
          columns: this.columns,
          pagination: this.pagination,
          searchFilter: ""
        };
      }

      // Abort any existing requests
      if (this.pendingGetJobsRequests.length > 0) {
        this.pendingGetJobsRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingGetJobsRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingGetJobsRequests.push(controller);

      // Configure current request filter params
      let filterParams = [];

      // Account for all column filters dynamically
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .forEach(column => {
          if (column.activeFilterCount > 0) {
            if (column.filterValues.in.length > 0) {
              filterParams.push({
                [column.name]: { in: column.filterValues.in }
              });
            }
            if (column.filterValues.notIn.length > 0) {
              filterParams.push({
                [column.name]: { not_in: column.filterValues.not_in }
              });
            }
          }
        });

      dataTableState.columns
        .filter(c => c.type === "metric")
        .forEach(column => {
          if (column.activeFilterCount > 0) {
            if (column.filterValues.gt.length > 0) {
              filterParams.push({
                [column.name]: { gte: +column.filterValues.gt }
              });
            }
            if (column.filterValues.lt.length > 0) {
              filterParams.push({
                [column.name]: { lte: +column.filterValues.lt }
              });
            }
          }
        });

      const sorting = [];
      if (dataTableState.pagination.sortBy) {
        sorting.push({
          [dataTableState.pagination.sortBy]: dataTableState.pagination
            .descending
            ? "desc"
            : "asc"
        });
      }

      const facet_fields_obj = {};
      dataTableState.columns
        .filter(c => c.type === "dimension")
        .map(c => {
          facet_fields_obj[c.name] = [];
          return c.name;
        });

      const searchObj = {
        product_type: "jobs",
        filters: filterParams,
        options: {
          response_fields: dataTableState.columns
            .filter(c => c.visible)
            .map(c => c.name),
          faceting: {
            fields: facet_fields_obj,
            size: 1200
          }
        },
        pagination: {
          page: dataTableState.pagination.page,
          per_page: dataTableState.pagination.rowsPerPage
        },
        sorting,
        scoring: []
      };

      if (dataTableState.searchFilter.length > 2) {
        searchObj.options.keyword_search = {
          keyword: dataTableState.searchFilter,
          fields: ["product_id_search", "title", "city", "state", "soc_code", "company", "category"]
        };
        searchObj.sorting.unshift({ _score: "desc" });
      }

      axios
        .post("https://api-v2.jumpfeed.com/internal/products", searchObj, {
          headers: {
            "Content-Type": "application/json"
          },
          signal: signal
        })
        .then(response => {
          this.jobs = response.data.data.results;

          let totalJobs = parseInt(response.data.data.totals.results);
          this.pagination.rowsNumber = totalJobs > 10000 ? 10000 : totalJobs;
          this.pagination.totalRows = totalJobs;

          this.dimensionFilterOptionsByColumn = {};
          let facetsByColumn = response.data.metadata.facets;
          Object.keys(facetsByColumn).forEach(column => {
            this.dimensionFilterOptionsByColumn[column] = facetsByColumn[column]
              .filter(f => f.key.toString().length > 0)
              .map(facet => ({
                label: facet.key,
                value: facet.key
              }));
          });

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
